<template>
    <v-app id="inspire" :class="layoutFlag ? 'defaultApp' : 'modalApp'">
        <v-app-bar
            app
            elevation="3"
            height="84"
            clipped-left
            color="#4E87DD"
            v-if="layoutFlag"
            style="z-index: 198"
        >

            <v-app-bar-nav-icon color="white" @click="drawer = !drawer" style="opacity:0.5; "></v-app-bar-nav-icon>

            <v-toolbar-title>
                <v-img
                    width="27"
                    height="30"
                    src="/img/admin_logo.png"
                    @click="goDashboard"
                    style=" margin-right:218px;"
                ></v-img>
            </v-toolbar-title>


            <div class="flex-grow-1"></div>
            <v-btn small text class="white--text font-text-mid">{{ userName }}: {{ userId }}</v-btn>
            <span class="white--text font-text-mid">|</span>
            <v-btn small text class="white--text font-text-mid" v-on:click="doLogout">LOGOUT
                <v-icon class="ml-2">mdi-power</v-icon>
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            app
            clipped
            v-model="drawer"
            width="295"
            style="background-color:#F4F6F8; z-index: 197;"
            floating
            v-if="layoutFlag"
        >

            <v-divider class="mb-6"></v-divider>

            <left-menu>
            </left-menu>

        </v-navigation-drawer>

        <v-main>
            <router-view></router-view>

            <main-footer v-if="layoutFlag" ref="mainFooter"></main-footer>
        </v-main>


        <v-dialog v-model="popupModal" persistent max-width="500" @keydown.esc="closePopup()">
            <v-flex v-if="popupType === 'alert'">
                <popup-alert v-on:close="closePopup"></popup-alert>
            </v-flex>
            <v-flex v-if="popupType === 'confirm'">
                <popup-confirm v-on:ok="okPopup" v-on:close="closePopup"></popup-confirm>
            </v-flex>
            <v-flex v-if="popupType === 'agree'">
                <agree-doc-popup @close="closePopup"></agree-doc-popup>
            </v-flex>
            <v-flex v-if="popupType === 'addrBook'">
                <popup-addr-book v-on:ok="okPopup" v-on:close="closePopup"></popup-addr-book>
            </v-flex>
        </v-dialog>
    </v-app>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'App',
    components: {
        'LeftMenu': () => import('@/components/common/left_menu'),
        'MainFooter': () => import('@/components/common/main_footer'),
        'PopupAlert': () => import('@/components/common/popup_alert'),
        'PopupConfirm': () => import('@/components/common/popup_confirm'),
        'popupAddrBook': () => import('@/components/common/popup_addr_book'),
    },
    data: () => ({
        drawer: null,
        managerName: '관리자',
        managerId: 'CallGate',
        modal: null,
    }),
    computed: {
        ...mapGetters({
            isAuthenticated: 'login/isAuthenticated',
            authUser: 'login/getAuthUser',
            accessToken: 'login/getAccessToken',
            userId: 'login/getUserId',
            userName: 'login/getUserName',
            layoutFlag: 'layoutFlag',
            popupModal: 'popupModal',
            popupType: 'popupType'
        }),
        appClass() {
            if (this.layoutFlag) {
                return 'defaultApp'
            }
            return 'modalApp'
        }
    },
    created() {
        let token = this.accessToken
        if (token.length > 0) {
            // check access token
            this.$store.dispatch('login/CHECK', {
                token: token,
                scb: (authSession) => {
                    // OK
                },
                fcb: (err) => {
                    // FAIL
                    this.$store.dispatch('login/LOGOUT', {serverFlag: true}).then(() => {
                        this.$router.push('/login')
                    })
                }
            })
        }
    },
    methods: {
        goDashboard() {
            if (this.$route.name != 'dashboard') {
                this.$router.push('/dashboard')
            }
        },
        doLogout() {
            this.$store.dispatch('login/LOGOUT', {serverFlag: true}).then(() => {
                this.$router.push('/login')
            })
        },

        closePopup() {
            this.$store.dispatch('closePopup')
        },

        okPopup() {
            this.$store.dispatch('okPopup')
        }
    }
};
</script>

<style type="text/css">
body {
    font: normal normal normal 16px/25px Apple SD Gothic Neo;
}

.defaultApp {
    background-color: #FFFFFF;
}

.modalApp {
    background-color: #F5F5F5 !important;
}

.cont-title {
    padding-top: 30px;
    font-size: 1.9em;
    font: normal normal bold 28px/33px Apple SD Gothic Neo !important;
    color: #333333;
}

.block-title {
    background-color: #F4F6F8;
    font-size: 23px;
    border-bottom: 1px solid #D3D8DD;
    height: 65px;
    font: normal normal 600 20px/25px Apple SD Gothic Neo !important;
    color: #333333;
}

.block-desc {
    font: normal normal normal 18px/37px Apple SD Gothic Neo !important;
    color: #555555;
}

.block-line-head {
    width: 250px;
    vertical-align: top;
    font: normal normal 600 18px/25px Apple SD Gothic Neo !important;
}

.block-br {
    border-right: 1px solid #D3D8DD;
    color: #333333;
}

.block-bb {
    border-bottom: 1px solid #D3D8DD;
    color: #333333;
}

.block-bt {
    border-top: 1px solid #D3D8DD;
    color: #333333;
}

.block-br {
    border-right: 1px solid #D3D8DD;
    color: #333333;
}

.font-text-mid {
    font: normal normal normal 18px/25px Apple SD Gothic Neo !important;
}

</style>

<style>
div.border-table table tr:first-child th {
    border-style: solid none none solid;
    border-width: thin;
    border-color: rgba(0, 0, 0, 0.12);
}
div.border-table table tr th:first-child, div.border-table table tr th, div.border-table table tr td {
    border-left: thin solid rgba(0, 0, 0, 0.12);
}
div.border-table table tr:first-child th:last-child, div.border-table table tr td:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12);
}
div.border-table table tr:last-child td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
div.border-table table tr th.border-th-bottom {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
div.border-table table tr.no-hover {
    background: transparent !important;
}
</style>
