import axios from 'axios'
import CONF from '@/config.js'

import commonApi from '@/api/_common.js'

export default {
    checkId(param, scb, fcb, va) {
        let adminUserId = (param && param.admin_user_id) ? param.admin_user_id : ''
        let reqURL = '/join/check_id?admin_user_id=' + adminUserId

        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.dup_type, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    sendSms(param, scb, fcb, va) {
        let reqURL = '/join/send_sms'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.join_tid, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        })
    },
    retrySendSms(param, scb, fcb, va) {
        let reqURL = '/join/retry_send_sms'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    verifySms(param, scb, fcb, va) {
        let reqURL = '/join/verify_sms'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.verify_result, data.invited_yn, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    submitInfo(param, scb, fcb, va) {
        let reqURL = '/join/submit_info'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.product, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    submitInvited(param, scb, fcb, va) {
        let reqURL = '/join/submit_invited'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.auth_session, va)
                axios.defaults.headers.common['Authorization'] = `Bearer ${data.auth_session.auth_token}`;
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    startBillKeyTran(param, scb, fcb, va) {
        let reqURL = '/join/start_bill_key_tran'

        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.pg_req, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        })
    },
    getJoinStepInfo(param, scb, fcb, va) {
        let reqURL = '/join/step_info'

        commonApi.POST(reqURL, param,(data) => {
            if (scb) {
                scb(data.join_form_step01, data.join_form_step02, data.join_form_step03, data.join_form_step04, data.product, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    uploadImage: function (param, scb, fcb) {
        let formData = new FormData()
        formData.append('img_type', param.img_type)
        formData.append('content_file', param.img_file)

        return axios.post(CONF.API_BASE_URL + '/join/upload_img/' + param.join_tid,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((result) => {
            if (result.data && result.data.success) {
                if (scb) {
                    scb(result.data)
                }
            } else {
                // fail to upload image
                if (fcb) {
                    if (result.data && result.data.result_desc) {
                        fcb(result.data.result_desc, true)
                    } else {
                        fcb('error', true)
                    }
                }
            }
        })
            .catch(function (err) {
                // fail to upload image
                console.log('Fail to upload image : ', err)
                if (fcb) {
                    fcb(err, false)
                }
            });
    },

    submitJoin(param, scb, fcb, va) {
        let reqURL = '/join/submit'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    submitSmsVerify(param, scb, fcb, va) {
        let reqURL = '/join/submit_sms_verify'
        commonApi.POST(reqURL, param, () => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    getRecommSvcMdn(param, scb, fcb, va) {
        let reqURL = '/join/recomm_svc_mdn'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.recomm_svc_mdn, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    getWishSvcMdnList(param, scb, fcb, va) {
        let reqURL = '/join/wish_svc_mdn'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.wish_svc_mdn_list, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    submitSvcMdn(param, scb, fcb, va) {
        let reqURL = '/join/submit_svc_mdn'
        commonApi.POST(reqURL, param, () => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    }
}