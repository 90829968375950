import Vue from 'vue'
import svcStaffApi from '@/api/svc_staff'

export default {
    namespaced: true,
    state: {
        origList: [],
        staffList: [],
    },
    getters: {
        staffList(state) {
            return state.staffList
        }
    },
    actions: {
        getList({commit}, payload) {
            svcStaffApi.getList({}, (staffList, va) => {
                if (staffList) {
                    staffList.forEach(item => {
                        item.edit_status = 'NONE'
                    })
                }
                commit('setStaffList', staffList)
                if (payload.scb) {
                    payload.scb(staffList, va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
        submitList({state, commit}, payload) {
            let staffList = []
            let idx = 1
            state.staffList.forEach(item => {
                if (item.edit_status != 'DELETE') {
                    staffList.push({
                        'user_name': item.user_name,
                        'user_mdn': item.user_mdn,
                        'mng_memo': item.mng_memo,
                        'device_type': item.device_type,
                        'user_order': idx++,
                    })
                }
            })

            svcStaffApi.submitList({'staff_list': staffList}, payload.scb, payload.fcb)
        }
    },
    mutations: {
        setStaffList(state, c) {
            let l = []
            c.forEach(item => {
                l.push(item)
            })

            state.staffList = l
        },
        addStaff(state, c) {
            c.edit_status = 'ADD'

            state.staffList.push(c)
        },
        delStaff(state, c) {
            let idx = c
            if (idx >= 0 && state.staffList.length > idx) {
                let item = state.staffList[idx]

                item.edit_status = 'DELETE'

                Vue.set(state.staffList, idx, item)
            }
        },
        restoreStaff(state, c) {
            let idx = c
            if (idx >= 0 && state.staffList.length > idx) {
                let item = state.staffList[idx]

                item.edit_status = 'NONE'

                Vue.set(state.staffList, idx, item)
            }
        },
        modifyStaff(state, c) {
            let idx = c.idx
            if (idx >= 0 && state.staffList.length > idx) {
                let item = state.staffList[idx]

                if (item.user_name == c.user_name && item.mng_memo == c.mng_memo) {
                    return
                }

                item.user_name = c.user_name
                item.mng_memo = c.mng_memo
                item.edit_status = 'MODIFY'

                Vue.set(state.staffList, idx, item)
            }

        }
    }
}