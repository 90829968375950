import commonApi from '@/api/_common.js'

export default {
    getCompanyPay(param, scb, fcb, va) {
        let reqURL = '/pay/company_pay'
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.company_pay, data.bill_list, va)
            }
        }, (err) => {
            console.log('api.pay.getCompanyPay : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    getBillDetailList(param, scb, fcb, va) {
        let reqURL = '/pay/bill_detail/' + param.bill_seqno + '/list'
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.bill_detail_list, va)
            }
        }, (err) => {
            console.log('api.pay.getBillDetailList : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    startBillKeyTran: function (param, scb, fcb, va) {
        let reqUrl = '/pay/start_bill_key_tran'

        commonApi.POST(reqUrl, param, (data) => {
            if (scb) {
                scb(data.pg_req)
            }
        }, (err) => {
            console.log('api.pay.startBillKeyTran : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        }, va)
    },
    changeBillKey: function (param, scb, fcb, va) {
        let reqUrl = '/pay/change_bill_key'

        commonApi.POST(reqUrl, param, (data) => {
            if (scb) {
                scb()
            }
        }, (err) => {
            console.log('api.pay.changeBillKey : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        }, va)
    }
}