import commonApi from '@/api/_common.js'

export default {
    getMenuList  (param, scb, fcb, va) { 
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0  && searchStart.length == 10) { 
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0  && searchEnd.length == 10) { 
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }        

        var reqURL = '/stat/menu_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd
        commonApi.GET(reqURL, (data) => { 
            if(scb) {
                scb(data.stat_list, data.total_count, va)
            }
        }, (err) => { 
            console.log('api.stats.getMenuList : FAIL : ', err)
            if(fcb) { 
                fcb(err, va)
            }
        }, va)
    },

    getUserList  (param, scb, fcb, va) { 
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0  && searchStart.length == 10) { 
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0  && searchEnd.length == 10) { 
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }        

        var reqURL = '/stat/user_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd
        commonApi.GET(reqURL, (data) => { 
            if(scb) {
                scb(data.stat_list, data.total_count, va)
            }
        }, (err) => { 
            console.log('api.stats.getUserList : FAIL : ', err)
            if(fcb) { 
                fcb(err, va)
            }
        }, va)
    },    


    getSvcCallList  (param, scb, fcb, va) { 
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0  && searchStart.length == 10) { 
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0  && searchEnd.length == 10) { 
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }   

        var reqURL = '/stat/svc_call_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd 
        commonApi.GET(reqURL, (data) => { 
            if(scb) {
                scb(data.stat_list, data.total_count, va)
            }
        }, (err) => { 
            console.log('api.stats.getSvcCallList : FAIL : ', err)
            if(fcb) { 
                fcb(err, va)
            }
        }, va)
    },         
}