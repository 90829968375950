import Vue from 'vue'
import svcVoiceScnApi from '@/api/svc_voice_scn'

export default { 
    namespaced: true,
    state: {
        menuList: [],
        menuTree: [],
        selectedMenu: null,
    },
    getters: {
        getMenuList (state) { 
            return state.menuList
        },
        getMenuTree (state) { 
            return state.menuTree
        },
        getSelectedMenu (state) { 
            return state.selectedMenu
        },
        getSelectedMenuSeqno (state) { 
            if(state.selectedMenu) { 
                return state.selectedMenu.menu_seqno
            } 
            return 0
        }
    },
    actions: {   
        getMenuList({ commit }, payload) {            
            svcVoiceScnApi.getMenuList(payload.param, (menuList) => { 
                commit('setMenuList', menuList)
                commit('resetTreeFlags', {})

                if(payload.scb) { 
                    payload.scb(menuList)
                }
            }, (err) => { 
                if(payload.fcb) { 
                    payload.fcb(err)
                }
            })
        },
        modifyMenu({ state, commit }, payload) { 
            svcVoiceScnApi.modifyMenu(state.selectedMenu, () => { 
                if(payload.scb) { 
                    payload.scb(payload.va)
                }
            }, (err) => { 
                if(payload.fcb) { 
                    payload.fcb(err, payload.va)
                }
            }, payload.va)
        },
    },
    mutations: {
        setMenuList (state, c) { 
            state.menuList.splice(0, state.menuList.length)
            c.forEach(item => { 
                state.menuList.push(item)
            })

            // generate menuTree struct
            let menuTree = []
            c.forEach(item => { 
                menuTree.push({
                    open_flag: false,
                    view_flag: item.menu_depth == 1,
                    change_order_flag: true,
                    add_child_flag: item.menu_depth < 4 && item.menu_type == 'FOLDER',
                    menu_seqno: item.menu_seqno,
                    menu_depth: item.menu_depth,
                    menu_type: item.menu_type,                    
                    item: item,
                })                    
            })

            let treeStatBackup = {}
            state.menuTree.forEach(item => { 
                treeStatBackup['' + item.menu_seqno] = {
                    menu_seqno: item.menu_seqno,
                    open_flag: item.open_flag,
                    view_flag: item.view_flag,
                }
            })

            state.menuTree.splice(0, state.menuTree.length)
            menuTree.forEach(item => {
                let b = treeStatBackup['' + item.menu_seqno]
                if(b) { 
                    item.open_flag = b.open_flag
                    item.view_flag = b.view_flag
                }
                state.menuTree.push(item)
            })

        },
        toggleMenuOpen (state, c) { 
            let oldFlag = c.open_flag
            let nextFlag = !oldFlag

            c.open_flag = nextFlag
        },
        resetTreeFlags (state, c) { 
            // reset view_flag, change_order_flag
            let menuTree = state.menuTree 
            let l1List = []
            let l2List = []
            let l3List = []
            let l4List = []
        
            menuTree.forEach(menuItem => { 
                if(menuItem.menu_depth == 1) { 
                    l1List.push(menuItem)
                }
                if(menuItem.menu_depth == 2) { 
                    l2List.push(menuItem)
                }
                if(menuItem.menu_depth == 3) { 
                    l3List.push(menuItem)
                }
                if(menuItem.menu_depth == 4) { 
                    l4List.push(menuItem)
                }
            })

            l1List.forEach(l1Item => { 
                l1Item.change_order_flag = (l1List.length > 1)
                let brotherCnt = 0
                l2List.forEach(l2Item => { 
                    if(l2Item.item.parent_menu_seqno == l1Item.menu_seqno) { 
                        brotherCnt++
                    }
                })

                l2List.forEach(l2Item => { 
                    if(l2Item.item.parent_menu_seqno == l1Item.menu_seqno) { 
                        l2Item.change_order_flag = (brotherCnt > 1)
                        l2Item.view_flag = l1Item.open_flag
                    }
                })
            })
            l2List.forEach(l2Item => { 
                let brotherCnt = 0
                l3List.forEach(l3Item => { 
                    if(l3Item.item.parent_menu_seqno == l2Item.menu_seqno) { 
                        brotherCnt++
                    }
                })

                l3List.forEach(l3Item => { 
                    if(l3Item.item.parent_menu_seqno == l2Item.menu_seqno) { 
                        l3Item.change_order_flag = (brotherCnt > 1)
                        l3Item.view_flag = l2Item.open_flag & l2Item.view_flag
                    }
                })
            })
            l3List.forEach(l3Item => { 
                let brotherCnt = 0
                l4List.forEach(l4Item => { 
                    if(l4Item.item.parent_menu_seqno == l3Item.menu_seqno) { 
                        brotherCnt++
                    }
                })

                l4List.forEach(l4Item => { 
                    if(l4Item.item.parent_menu_seqno == l3Item.menu_seqno) { 
                        l4Item.change_order_flag = (brotherCnt > 1)
                        l4Item.view_flag = l3Item.open_flag & l3Item.view_flag
                    }
                })
            })
        },
        selectMenu (state, c) { 
            if(c && c.menu_seqno > 0) { 
                let m = null
                state.menuList.forEach(menuItem => { 
                    if(menuItem.menu_seqno == c.menu_seqno) { 
                        m =  Object.assign({}, menuItem)
                        if(m.ring_user_list) {
                            let tmpArr = []
                            m.ring_user_list.forEach(ringUserItem => { 
                                tmpArr.push(Object.assign({}, ringUserItem))
                            })
                            m.ring_user_list = tmpArr
                        }
                    }
                })
                if(m) { 
                    state.selectedMenu = m
                }
            } else { 
                state.selectedMenu = null
            }
        },
        addRingUserList (state, c) { 
            if(c) {
                let tmpArr = state.selectedMenu.ring_user_list

                if(tmpArr == null || tmpArr == undefined) {
                    tmpArr = new Array()
                }
                c.forEach(item => { 
                    tmpArr.push(item)
                })

                state.selectedMenu.ring_user_list = tmpArr
            }
        },
        deleteRingUser (state, c) { 
            let userSeqno = c.user_seqno

            let targetIdx = -1
            state.selectedMenu.ring_user_list.forEach((ringUser, rinUserIdx) => {
                if(ringUser.user_seqno == c.user_seqno){
                    targetIdx = rinUserIdx
                }
            })

            if(targetIdx >= 0){ 
                state.selectedMenu.ring_user_list.splice(targetIdx, 1)
            }
            
        },
        resetRingUserOrder (state, c) { 
            let ringUserList = state.selectedMenu.ring_user_list

            ringUserList.forEach((item, idx) => { 
                item.display_order = idx+1
            })

        }
    }
}