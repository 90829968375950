import addrBookApi from '@/api/addr_book'

export default {
    namespaced: true,
    state: {
        searchParam: {},
        addrBookList: [],
        totalCount: 0,
        addrBook: {},
        attachment: {
            tmpFileName: '',
            file: undefined,
            errorMessage: '',
            errorSubMessage: ''
        }
    },
    getters: {
        getAttachment(state) {
            return state.attachment
        }
    },
    actions: {
        getList({commit}, payload) {

            commit('setSearchParam', payload.param)

            addrBookApi.getList(payload.param, (addrBookList, totalCount, va) => {
                commit('setAddrBookList', addrBookList)
                commit('setTotalCount', totalCount)

                if (payload.scb) {
                    payload.scb(va)
                }

            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        }
    },
    mutations: {
        setAddrBookList(state, c) {
            state.addrBookList.splice(0, state.addrBookList.length)

            if (c) {
                c.forEach(item => {
                    state.addrBookList.push(item)
                })
            }
        },
        setTotalCount(state, c) {
            state.totalCount = c
        },
        setSearchParam(state, c) {
            state.searchParam = Object.assign({}, c)
        },
        setAddrBook(state, c) {
            state.addrBook = Object.assign({}, c)
        },
        setAttachment(state, c) {
            state.attachment = Object.assign({}, c)
        }
    }
}