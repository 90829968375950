import dashboardApi from '@/api/dashboard'

export default {
    namespaced: true,
    state: {
        company: {},
        stat: {},
        staffList: [],
    },
    getters: {
        getStat(state) {
            return state.stat
        },
        getStaffList(state) {
            return state.staffList
        }
    },
    actions: {
        getInfo({commit}, payload) {

            dashboardApi.getInfo({}, (company, stat, staff_list) => {
                commit('setInfo', {
                    company: company,
                    stat: stat,
                    staff_list: staff_list,
                })

                if (payload.scb) {
                    payload.scb(company, stat, staff_list)
                }
            }, (err) => {
                if (payload.fcb) {
                    payload.fcb(err)
                }
            })
        }
    },
    mutations: {
        setInfo(state, c) {
            if (!c) {
                return
            }

            if (c.company) {
                state.comapny = Object.assign({}, c.company)
            }
            if (c.stat) {
                state.stat = Object.assign({}, c.stat)
            }
            if (c.staff_list) {
                state.staffList.splice(0, state.staffList.length)
                c.staff_list.forEach(item => {
                    state.staffList.push(item)
                })
            }
        }
    }
}