import axios from 'axios'
import CONF from '@/config.js'

export default {
    namespaced: true,
    state: {
        accessToken: '',
        userId: '',
        userName: '',
        companyName: '',
        svcMdn: '',
        companyLvType: '',
        ptnCode: '',
        landlineUseYn: '',
        sipHost: '',
        sipPort: '',
        mainAdminYn: '',
        authSession: null,
        notice: null,
        noticeAlertFlag: false,
    },
    getters: {
        isAuthenticated(state) {
            state.accessToken = state.accessToken || localStorage.accessToken
            return (state.accessToken != null && state.accessToken != undefined && state.accessToken != 'undefined' && state.accessToken.length > 0)
        },
        getAccessToken(state) {
            return (state.accessToken != null && state.accessToken != undefined && state.accessToken != 'undefined' && state.accessToken.length > 0) ? state.accessToken : ''
        },
        getUserId(state) {
            state.userId = state.userId || localStorage.userId
            return (state.userId != null && state.userId != undefined) ? state.userId : ''
        },
        getUserName(state) {
            state.userName = state.userName || localStorage.userName
            return (state.userName != null && state.userName != undefined) ? state.userName : ''
        },
        getCompanyName(state) {
            state.companyName = state.companyName || localStorage.companyName
            return (state.companyName != null && state.companyName != undefined) ? state.companyName : ''
        },
        getSvcMdn(state) {
            state.svcMdn = state.svcMdn || localStorage.svcMdn
            return (state.svcMdn != null && state.svcMdn != undefined) ? state.svcMdn : ''
        },
        getCompanyLvType(state) {
            state.companyLvType = state.companyLvType || localStorage.companyLvType || 'PRO'
            return state.companyLvType
        },
        getPtnCode(state) {
            state.ptnCode = state.ptnCode || localStorage.ptnCode || 'CONTACT'
            return state.ptnCode
        },
        getLandlineUseYn(state) {
            state.landlineUseYn = state.landlineUseYn || localStorage.landlineUseYn || 'N'
            return state.landlineUseYn
        },
        getSipHost(state) {
            state.sipHost = state.sipHost || localStorage.sipHost
            return (state.sipHost != null && state.sipHost != undefined) ? state.sipHost : ''
        },
        getSipPort(state) {
            state.sipPort = state.sipPort || localStorage.sipPort
            return (state.sipPort != null && state.sipPort != undefined) ? state.sipPort : ''
        },
        getMainAdminYn(state) {
            state.mainAdminYn = state.mainAdminYn || localStorage.mainAdminYn || 'N'
            return state.mainAdminYn
        }
    },
    mutations: {
        setLogin(state, payload) {
            state.accessToken = payload.auth_token
            state.userId = payload.admin_user_id
            state.userName = payload.admin_user_name
            state.companyName = payload.company_name
            state.svcMdn = payload.svc_mdn
            state.authSession = payload
            state.companyLvType = payload.company_lv_type
            state.ptnCode = payload.ptn_code
            state.landlineUseYn = payload.landline_use_yn
            state.sipHost = payload.sip_host
            state.sipPort = payload.sip_port
            state.mainAdminYn = payload.main_admin_yn
            state.notice = Object.assign({}, payload.notice)

            localStorage.accessToken = payload.auth_token
            localStorage.userId = payload.admin_user_id
            localStorage.userName = payload.admin_user_name
            localStorage.companyName = payload.company_name
            localStorage.svcMdn = payload.svc_mdn
            localStorage.companyLvType = payload.company_lv_type
            localStorage.ptnCode = payload.ptn_code
            localStorage.landlineUseYn = payload.landline_use_yn
            localStorage.sipHost = payload.sip_host
            localStorage.sipPort = payload.sip_port
            localStorage.mainAdminYn = payload.main_admin_yn
            //localStorage.authSession = payload
        },
        setLogout(state) {
            state.accessToken = null
            delete localStorage.accessToken
        },
        setMainAdminYn(state, c) {
            if (c == 'Y' || c == 'N') {
                state.mainAdminYn = c
                localStorage.mainAdminYn = c
            }
        },
        noticeAlertFlag(state, f) {
            state.noticeAlertFlag = f
        }
    },
    actions: {
        LOGIN({commit}, {id, pass, scb, fcb}) {
            return axios.post(CONF.API_BASE_URL + '/auth/login', {user_id: id, password: pass})
                .then(({data}) => {
                    if (data && data.success) {
                        commit('setLogin', data.auth_session)

                        axios.defaults.headers.common['Authorization'] = `Bearer ${data.auth_session.auth_token}`;

                        if (scb) {
                            scb()
                        }
                    } else {
                        if (fcb) {
                            fcb(data.result_desc)
                        }
                    }

                })
        },
        async LOGOUT({commit}, {serverFlag}) {
            if (serverFlag) {
                await axios.post(CONF.API_BASE_URL + '/auth/logout')
                    .then(({data}) => {
                        if (data && data.success) {
                        }
                        axios.defaults.headers.common['Authorization'] = undefined
                        commit('setLogout')
                    })
            } else {
                axios.defaults.headers.common['Authorization'] = undefined
                commit('setLogout')
            }
        },
        CHECK({commit}, {token, scb, fcb}) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token

            let timestamp = new Date().getTime()
            axios.get(CONF.API_BASE_URL + '/auth/check?tm=' + timestamp)
                .then(function (result) {
                    if (result.data && result.data.success) {
                        if (scb) {
                            scb(result.data.auth_session)
                        }
                    } else {
                        // fail to check auth
                        console.log('Fail to check auth : ', result.data.result_desc)
                        axios.defaults.headers.common['Authorization'] = undefined
                        if (fcb) {
                            fcb(result.data.result_desc)
                        }
                    }
                })
                .catch(function (err) {
                    // fail to check auth
                    console.log('Fail to check auth : ', err)
                    axios.defaults.headers.common['Authorization'] = undefined
                    if (fcb) {
                        fcb(err)
                    }
                })
        }
    },

}