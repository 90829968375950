import svcHistApi from '@/api/svc_hist'


export default {
    namespaced: true,
    state: {
        searchParam: {
            search_start: '',
            search_end: '',
            page_no: 1,
            page_size: 20
        },
        totalCount: 0,
        statList: [],
        svcCallSearchParam: {
            call_sid: ''
        },
        svcCallList: [],
    },
    getters: {
        getSearchParam(state) {
            return state.searchParam
        },
        getStatList(state) {
            return state.statList
        },
        getTotalCount(state) {
            return state.totalCount
        }
    },
    actions: {
        getStatList({state, commit}, payload) {
            if (payload.searchParam) {
                commit('setSearchParam', payload.searchParam)
            }

            svcHistApi.getSvcList(state.searchParam, (stat_list, total_count, va) => {
                commit('setStatList', {stat_list: stat_list, total_count: total_count})
                if (payload.scb) {
                    payload.scb(stat_list, total_count, va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
        getSvcCallList({state, commit}, payload) {
            if (payload.searchParam) {
                commit('setSvcCallSearchParam', payload.searchParam)
            }

            svcHistApi.getSvcCallList(payload.searchParam, (svc_call_list, va) => {
                commit('setSvcCallList', svc_call_list)
                if (payload.scb) {
                    payload.scb(svc_call_list, va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        }
    },
    mutations: {
        setStatList(state, c) {
            if (!c) {
                return
            }

            state.statList.splice(0, state.statList.length)
            if (c.stat_list) {
                c.stat_list.forEach(item => {
                    state.statList.push(item)
                })
            }
            state.totalCount = c.total_count ? c.total_count : 0
        },
        setSearchParam(state, c) {
            state.searchParam = Object.assign({}, c)
        },
        setSvcCallList(state, c) {
            if (!c) {
                return
            }

            state.svcCallList.splice(0, state.svcCallList.length)
            if (c) {
                c.forEach(item => {
                    state.svcCallList.push(item)
                })
            }
        },
        setSvcCallSearchParam(state, c) {
            state.svcCallSearchParam = Object.assign({}, c)
        },
    }
}