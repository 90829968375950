import commonApi from '@/api/_common.js'

export default {
    getList  (param, scb, fcb, va) { 
        let reqURL = '/svc/staff/list'
        commonApi.GET(reqURL, (data) => { 
            if(scb) { 
                scb(data.staff_list, va)
            }
        }, (err) => { 
            console.log('api.svc_staff.getList : FAIL : ', err)
            if(fcb) { 
                fcb(err, va)
            }
        }, va)
    },
    checkDup (param, scb, fcb) { 
        let reqURL = '/svc/staff/check_dup'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) { 
                scb(data.dup_type)
            }
        }, (err) => { 
            console.log('api.svc_staff.checkDup : FAIL : ', err)
            if(fcb) { 
                fcb(err)
            }
        })  
    },
    submitList (param, scb, fcb) { 
        let reqURL = '/svc/staff/submit_list'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) { 
                scb()
            }
        }, (err) => { 
            console.log('api.svc_staff.submitList : FAIL : ', err)
            if(fcb) { 
                fcb(err)
            }
        })
    }
}