import adminApi from '@/api/admin'

export default { 
    namespaced: true,
    state: {
        adminUser: {},
        company: {},
        adminUserList: [],
        adminHistoryList: [],
        billDetailList: [],
        totalBillingAmount: 0,
        prevBillMonth: '',
        prevBillMonthPaidYn: ''
    },
    getters: {
        getAdminUser (state) { 
            return state.adminUser
        },
        getCompany (state) { 
            return state.company
        },
        getAdminUserList (state) { 
            return state.adminUserList
        },
        getAdminHistoryList (state) { 
            return state.adminHistoryList
        }
    },
    actions: {        
        getInfo ({ state, commit }, payload) { 
            adminApi.getInfo({}, (company, admin_user, admin_user_list, admin_history_list, va) => { 
                commit('setInfo', {company: company, admin_user: admin_user, admin_user_list: admin_user_list, admin_history_list: admin_history_list})
                if(payload.scb) { 
                    payload.scb(company, admin_user, admin_user_list, admin_history_list)
                }
            }, err => { 
                if(payload.fcb) { 
                    payload.fcb(err)
                }
            }, payload.va)
        },
        getBillDetailList({commit}, payload) {
            adminApi.getBillDetailList(payload.param, (list, totalBillingAmount, prevBillMonth, prevBillMonthPaidYn, va) => {

                commit('setBillDetailList', list)
                commit('setTotalBillingAmount', totalBillingAmount)
                commit('setPrevBillMonth', prevBillMonth)
                commit('setPrevBillMonthPaidYn', prevBillMonthPaidYn)

                if (payload.scb) {
                    payload.scb(va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        }
    },
    mutations: {
        setInfo (state, c) { 
            if(!c) {  return  }

            state.adminUser = Object.assign({}, c.admin_user)
            state.company = Object.assign({}, c.company)

            state.adminUserList.splice(0, state.adminUserList.length)
            state.adminHistoryList.splice(0, state.adminHistoryList.length)

            if(c.admin_user_list) { 
                c.admin_user_list.forEach(item => { 
                    state.adminUserList.push(item)
                })
            }
            if(c.admin_history_list) { 
                c.admin_history_list.forEach(item => { 
                    state.adminHistoryList.push(item)
                })
            }
        },
        setBillDetailList(state, c) {
            state.billDetailList.splice(0, state.billDetailList.length)

            if (c) {
                c.forEach(item => {
                    state.billDetailList.push(item)
                })
            }
        },
        setTotalBillingAmount(state, c) {
            if (c) {
                state.totalBillingAmount = c
            } else {
                state.totalBillingAmount = 0
            }
        },
        setPrevBillMonth(state, c) {
            state.prevBillMonth = c
        },
        setPrevBillMonthPaidYn(state, c) {
            state.prevBillMonthPaidYn = c
        }
    }
}