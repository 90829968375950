import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/trand_chart'
import './plugins/commonFilter';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false


Vue.prototype.$noSessionProc = function (err) {
  if (err == 'NO_SESSION') { 
      alert('로그아웃 되었습니다')

      this.$store.dispatch('login/LOGOUT', {serverFlag: false}).then(() => {
          this.$router.push('/login')
      })

      return true
  }
  return false
}

Vue.use(VueCookies)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
