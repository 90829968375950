import axios from 'axios'
import CONF from '@/config.js'
import commonApi from '@/api/_common.js'

export default {
    getConfig(param, scb, fcb, va) {
        let reqURL = '/svc/voice_intro/config'
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.conf, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    doSubmit(param, scb, fcb) {
        let reqURL = CONF.API_BASE_URL + '/svc/voice_intro/submit'
        return axios.post(reqURL, param)
            .then((result) => {
                if (result.data && result.data.success) {
                    if (scb) {
                        scb()
                    }
                } else {
                    // fail to submit config
                    console.log('api.svc_voice_intro.doSubmit : Fail to execute')
                    if (fcb) {
                        if (result.data && result.data.result_desc) {
                            fcb(result.data.result_desc)
                        } else {
                            fcb('error')
                        }
                    }
                }
            })
            .catch(function (err) {
                // fail to submit config
                console.log('Fail to submit config : ', err)
                if (fcb) {
                    fcb(err)
                }
            })
    }
}