import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        meta: {authRequired: true}
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackCunkName: "dashbaord" */ '../views/dashboard.vue'),
        meta: {authRequired: true}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
        meta: {authRequired: false}
    },
    {
        path: '/join',
        component: () => import(/* webpackChunkName: "join" */ '../views/join.vue'),
        meta: {authRequired: false},
        children: [
            {
                path: '',
                redirect: 'step01'
            },
            {
                path: 'step01',
                component: () => import('@/components/join/subview_step01')
            },
            {
                path: 'step02/:join_tid',
                component: () => import('@/components/join/subview_step02')
            },
            {
                path: 'step03/:join_tid',
                component: () => import('@/components/join/subview_step03')
            },
            {
                path: 'step04/:join_tid',
                component: () => import('@/components/join/subview_step04')
            },
            {
                path: 'step05/:join_tid',
                component: () => import('@/components/join/subview_step05')
            },
            {
                path: 'step05_fail/:join_tid',
                component: () => import('@/components/join/subview_step05_fail')
            },
            {
                path: 'step06',
                component: () => import('@/components/join/subview_step06')
            },
            {
                path: 'wait_approval',
                component: () => import('@/components/join/subview_wait_approval')
            }
        ],
    },
    {
        path: '/svc/staff',
        component: () => import(/* webpackChunkName: "svc_staff" */ '../views/svc_staff.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: '',
                redirect: 'list'
            },
            {
                path: 'list',
                component: () => import('@/components/svc/staff/subview_list')
            },
        ],
    },
    {
        path: '/svc/voice_intro',
        component: () => import(/* webpackChunkName: "svc_voice_intro" */ '../views/svc_voice_intro.vue'),
        meta: {authRequired: true},
    },
    {
        path: '/svc/voice_scn',
        component: () => import(/* webpackChunkName: "svc_voice_scn" */ '../views/svc_voice_scn.vue'),
        meta: {authRequired: true},
    },
    {
        path: '/svc/wv',
        component: () => import(/* webpackChunkName: "svc_wv" */ '../views/svc_wv.vue'),
        meta: {authRequired: true},
    },
    {
        path: '/svc/block_mdn',
        component: () => import(/* webpackChunkName: "svc_block_mdn" */ '../views/svc_block_mdn.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: '',
                redirect: 'list'
            },
            {
                path: 'list',
                component: () => import('@/components/svc/block_mdn/subview_block_mdn')
            }
        ]
    },
    {
        path: '/addr_book',
        component: () => import(/* webpackChunkName: "addr_book" */ '../views/addr_book.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: 'list',
                component: () => import('@/components/addr_book/subview_addr_book')
            }
        ]
    },
    {
        path: '/svc_hist',
        component: () => import(/* webpackChunkName: "svc_hist" */ '../views/svc_hist.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: '',
                redirect: 'recv_call_list'
            },
            {
                path: 'recv_call_list',
                component: () => import('@/components/svc_hist/subview_recv_call_list'),
                meta: {title: '수신통화 이력'}
            },
            {
                path: 'svc_list',
                component: () => import('@/components/svc_hist/subview_svc_list'),
                meta: {title: '수신통화 이력'}
            },
            {
                path: 'send_call_list',
                component: () => import('@/components/svc_hist/subview_send_call_list'),
                meta: {title: '발신통화 이력'}
            },
            {
                path: 'callback_list',
                component: () => import('@/components/svc_hist/subview_callback_list'),
                meta: {title: '콜백처리 이력'}
            },
            {
                path: 'cust_memo_group_list',
                component: () => import('@/components/svc_hist/subview_cust_memo_group_list'),
                meta: {title: '메모'}
            },
        ]
    },
    {
        path: '/stats',
        component: () => import(/* webpackChunkName: "stats" */ '../views/stats.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: '',
                redirect: 'menu_list'
            },
            {
                path: 'menu_list',
                component: () => import('@/components/stats/subview_menu_list'),
                meta: {title: 'ARS메뉴별 통계'}
            },
            {
                path: 'user_list',
                component: () => import('@/components/stats/subview_user_list'),
                meta: {title: '콜리별 통계'}
            },
            {
                path: 'svc_call_list',
                component: () => import('@/components/stats/subview_svc_call_list'),
                meta: {title: '수신통화 통계'}
            }
        ]
    },
    {
        path: '/pay',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: '',
                redirect: 'status'
            },
            {
                path: 'status',
                component: () => import('@/components/pay/subview_status')
            },
            {
                path: 'forward_pg',
                component: () => import('@/components/pay/subview_forward_pg')
            },
            {
                path: 'return_pg/:bktr_tid',
                component: () => import('@/components/pay/subview_return_pg')
            }
        ]
    },
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin.vue'),
        meta: {authRequired: true},
        children: [
            {
                path: '',
                redirect: 'info',
            },
            {
                path: 'info',
                component: () => import('@/components/admin/subview_info')
            },
            {
                path: 'secede',
                component: () => import('@/components/admin/subview_secede')
            },
        ],
    },
    {
        path: '/help',
        component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
        meta: {authRequired: true},
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    },
    routes
})


router.beforeEach((to, from, next) => {

    let toIdx = to.matched.length - 1
    let title = ''
    let authRequired = false

    for (let i = 0; i <= toIdx; i++) {
        if (to.matched[i].meta) {
            if (to.matched[i].meta.title) {
                title = to.matched[i].meta.title
            }
            if (to.matched[i].meta.authRequired) {
                authRequired = to.matched[i].meta.authRequired
            }
        }
    }
    if (to.matched[toIdx]) {
        if (!to.matched[toIdx].meta) {
            to.matched[toIdx].meta = {}
        }
        to.matched[toIdx].meta.title = title
        to.matched[toIdx].meta.authRequired = authRequired
    }

    let isAuth = store.getters['login/isAuthenticated']

    if (authRequired) {
        if (isAuth == false) {
            next({path: '/login'})
            return
        }
    } else {
        if (isAuth) {
            next({path: '/dashboard'})
            return
        }
    }
    next()
})


export default router
