import statsApi from '@/api/stats'

export default { 
    namespaced: true,
    state: {
        searchParam: {
            search_start: '',
            search_end: '',
        },
        totalCount: 0,
        statList: [],
    },
    getters: {
        getSearchParam (state) { 
            return state.searchParam
        },
        getStatList (state) { 
            return state.statList
        },
        getTotalCount (state) { 
            return state.totalCount
        }
    },
    actions: {        
        getStatList ({ state, commit }, payload) { 
            if(payload.searchParam) { 
                commit('setSearchParam', payload.searchParam)
            }

            statsApi.getSvcCallList(state.searchParam, (stat_list, total_count, va) => { 
                commit('setStatList', {stat_list: stat_list, total_count: total_count})
                if(payload.scb) { 
                    payload.scb(stat_list, total_count, va)
                }
            }, (err, va) => { 
                if(payload.fcb) { 
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
    },
    mutations: {
        setStatList (state, c) { 
            if(!c) {  return  }

            state.statList.splice(0, state.statList.length)
            if(c.stat_list) { 
                c.stat_list.forEach(item => { 
                    state.statList.push(item)
                })
            }
            state.totalCount = c.total_count ? c.total_count : 0
        },        
        setSearchParam (state, c) { 
            state.searchParam = Object.assign({}, c)
        }
    }
}