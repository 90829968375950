import Vue from 'vue'

Vue.filter('number', function (value) {
    if (value == null || value == undefined) return '0'
    return Number(value).toLocaleString()
})


Vue.filter('dateMonth', function (value) {
    if (!value) return ''

    let isNumOnly = /^\d+$/.test(value)
    //let isNumOnly = string.match(/^[0-9]+$/) != null
    if (isNumOnly == false) {
        return value
    }

    let len = value.length
    let ret = ''
    if (len < 6) {
        ret = value
    } else {
        if (len >= 6) {
            ret = value.substring(0, 4) + '/' + value.substring(4, 6)
        }
    }

    return ret
})


Vue.filter('dateMin', function (value) {
    if (!value) return ''

    let isNumOnly = /^\d+$/.test(value)
    //let isNumOnly = string.match(/^[0-9]+$/) != null
    if (isNumOnly == false) {
        return value
    }

    let len = value.length
    let ret = ''
    if (len < 8) {
        ret = value
    } else {
        if (len >= 8) {
            ret = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
        }
        if (len >= 10) {
            ret = ret + ' ' + value.substring(8, 10)
        }
        if (len >= 12) {
            ret = ret + ':' + value.substring(10, 12)
        }
    }

    return ret
})

Vue.filter('dateSec', function (value) {
    if (!value) return ''

    let isNumOnly = /^\d+$/.test(value)
    //let isNumOnly = string.match(/^[0-9]+$/) != null
    if (isNumOnly == false) {
        return value
    }

    let len = value.length
    let ret = ''
    if (len < 8) {
        ret = value
    } else {
        if (len >= 8) {
            ret = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
        }
        if (len >= 10) {
            ret = ret + ' ' + value.substring(8, 10)
        }
        if (len >= 12) {
            ret = ret + ':' + value.substring(10, 12)
        }
        if (len >= 14) {
            ret = ret + ':' + value.substring(12, 14)
        }
    }

    return ret
})

Vue.filter('secTime', function (value) {
    try {
        if (!value) {
            return '00:00:00'
        }

        let s = value % 60
        let m = ((value - s) / 60) % 60
        let h = (value - s - m * 60) / (60 * 60) % 60

        return ((h < 10) ? '0' : '') + h + ':' + ((m < 10) ? '0' : '') + m + ':' + ((s < 10) ? '0' : '') + s
    } catch (e) {
        console.log('error : secTime filter : ', err)
        return value
    }
})

Vue.filter('phoneNum', function (value) {
    try {
        if (!value) return ''

        let isNumOnly = /^\d|[*]+$/.test(value)
        //let isNumOnly = string.match(/^[0-9]+$/) != null
        if (isNumOnly == false) {
            return value
        }

        let len = value.length
        let ret = ''
        if (value.startsWith('0') == false) {
            ret = value
        } else if (len == 11 && (value.startsWith('01') || value.startsWith('070') || value.startsWith('050') || value.startsWith('060'))) {
            ret = value.substring(0, 3) + '-' + value.substring(3, 7) + '-' + value.substring(7)
        } else if (len == 10 && value.startsWith('01')) {
            ret = value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6)
        } else if (len == 10 && value.startsWith('02')) {
            ret = value.substring(0, 2) + '-' + value.substring(2, 6) + '-' + value.substring(6)
        } else if (len == 9 && value.startsWith('02')) {
            ret = value.substring(0, 2) + '-' + value.substring(2, 5) + '-' + value.substring(5)
        } else if (len == 11 && (value.startsWith('031') || value.startsWith('032') || value.startsWith('033') || value.startsWith('041') || value.startsWith('042') || value.startsWith('043') || value.startsWith('051') || value.startsWith('052') || value.startsWith('053') || value.startsWith('054') || value.startsWith('055') || value.startsWith('061') || value.startsWith('062') || value.startsWith('063') || value.startsWith('064'))) {
            ret = value.substring(0, 3) + '-' + value.substring(3, 7) + '-' + value.substring(7)
        } else if (len == 10 && (value.startsWith('031') || value.startsWith('032') || value.startsWith('033') || value.startsWith('041') || value.startsWith('042') || value.startsWith('043') || value.startsWith('051') || value.startsWith('052') || value.startsWith('053') || value.startsWith('054') || value.startsWith('055') || value.startsWith('061') || value.startsWith('062') || value.startsWith('063') || value.startsWith('064'))) {
            ret = value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6)
        } else {
            ret = value
        }


        return ret
    } catch (e) {
        console.log('error: phoneNum filter : ', err)
        return value
    }
})

Vue.filter('corpRegNum', function (value) {
    try {
        if (!value) {
            return ''
        }

        let isNumOnly = /^\d|[*]+$/.test(value)
        //let isNumOnly = string.match(/^[0-9]+$/) != null
        if (isNumOnly == false) {
            return value
        }

        let len = value.length
        let ret = ''

        if (len == 10) {
            ret = value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5)
        }
        if (ret.length == 0) {
            return value
        }

        return ret

    } catch (e) {
        console.log('error: corpRegNum filter : ', e)
        return value
    }
})

Vue.filter('dateOnly', function (value) {
    if (!value) return ''

    let isNumOnly = /^\d+$/.test(value)
    if (isNumOnly == false) {
        return value
    }

    let len = value.length
    let ret = ''
    if (len < 8) {
        ret = value
    } else {
        if (len >= 8) {
            ret = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
        }
    }

    return ret
})

Vue.filter('isNull', (v) => {
    if (v) {
        v = v.replace(/s+g/, '')
    }

    return typeof v == 'undefined' || v.length == 0 || v == null ? true : false
})