import svcHistApi from '@/api/svc_hist'

export default {
    namespaced: true,
    state: {
        groupSearchParam: {
            page_no: 1,
            page_size: 20
        },
        groupTotalCount: 0,
        groupList: [],
        memoSearchParam: {
            page_no: 1,
            page_size: 20
        },
        memoTotalCount: 0,
        memoList: [],
        groupDetail: {},
    },
    getters: {
        getGroupSearchParam(state) {
            return state.groupSearchParam
        },
        getGroupList(state) {
            return state.groupList
        },
        getGroupTotalCount(state) {
            return state.groupTotalCount
        },
        getMemoSearchParam(state) {
            return state.memoSearchParam
        },
        getMemoList(state) {
            return state.memoList
        },
        getMemoTotalCount(state) {
            return state.memoTotalCount
        },
    },
    actions: {
        getGroupList({state, commit}, payload) {
            if (payload.searchParam) {
                commit('setGroupSearchParam', payload.searchParam)
            }

            svcHistApi.getCustMemoGroupList(state.groupSearchParam, (groupList, totalCount, pageNo, va) => {
                commit('setGroupList', {group_list: groupList, total_count: totalCount})
                if (payload.scb) {
                    payload.scb(groupList, totalCount, va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
        getMemoList({state, commit}, payload) {
            if (payload.searchParam) {
                commit('setMemoSearchParam', payload.searchParam)
            }

            svcHistApi.getCustMemoList(state.memoSearchParam, (memoList, memoGroup, va) => {
                commit('setMemoList', memoList)
                commit('setGroupDetail', memoGroup)

                if (payload.scb) {
                    payload.scb(va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
    },
    mutations: {
        setGroupList(state, c) {
            if (!c) {
                return
            }

            state.groupList.splice(0, state.groupList.length)
            if (c.group_list) {
                c.group_list.forEach(item => {
                    state.groupList.push(item)
                })
            }
            state.groupTotalCount = c.total_count ? c.total_count : 0
        },
        setGroupSearchParam(state, c) {
            state.groupSearchParam = Object.assign({}, c)
        },
        setMemoList(state, c) {
            if (!c) {
                return
            }

            console.log('setMemoList.c: ', c)
            state.memoList.splice(0, state.memoList.length, ...(c ? c : []))
            console.log('state.memoList: ', state.memoList)
        },
        setMemoSearchParam(state, c) {
            state.memoSearchParam = Object.assign({}, c)
        },
        setGroupDetail(state, c) {
            if (!c) {
                return
            }

            state.groupDetail = Object.assign({}, c)
        }
    }
} 