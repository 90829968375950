import axios from 'axios'
import CONF from '@/config.js'
import commonApi from '@/api/_common.js'

export default {
    getConfig(param, scb, fcb, va) {
        let reqURL = '/svc/wv/config'
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.conf)
            }
        }, (err) => {
            console.log('api.svc_wv.getConfig : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        }, va)
    },
    doSubmit(param, scb, fcb, va) {
        let reqURL = '/svc/wv/submit'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb()
            }
        }, (err) => {
            console.log('api.svc_wv.doSubmit : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        }, va)
    },
    uploadImage: function (imgFile, scb, fcb) {
        let formData = new FormData()
        formData.append('content_file', imgFile)

        return axios.post(CONF.API_BASE_URL + '/svc/wv/upload_img',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((result) => {
            if (result.data && result.data.success) {
                if (scb) {
                    scb(result.data)
                }
            } else {
                // fail to upload image
                console.log('Fail to upload image')
                if (fcb) {
                    if (result.data && result.data.result_desc) {
                        fcb(result.data.result_desc, true)
                    } else {
                        fcb('error', true)
                    }
                }
            }
        })
            .catch(function (err) {
                // fail to upload image
                console.log('Fail to upload image : ', err)
                if (fcb) {
                    fcb(err, false)
                }
            });
    },
    uploadLogoImage: function (imgFile, cropCoords, scb, fcb) {
        let formData = new FormData()
        formData.append('content_file', imgFile)
        if (cropCoords && cropCoords.top) {
            formData.append('crop_coords_top', cropCoords.top)
        }
        if (cropCoords && cropCoords.left) {
            formData.append('crop_coords_left', cropCoords.left)
        }
        if (cropCoords && cropCoords.width) {
            formData.append('crop_coords_width', cropCoords.width)
        }
        if (cropCoords && cropCoords.height) {
            formData.append('crop_coords_height', cropCoords.height)
        }

        return axios.post(CONF.API_BASE_URL + '/svc/wv/upload_logo_img',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((result) => {
            if (result.data && result.data.success) {
                if (scb) {
                    scb(result.data)
                }
            } else {
                // fail to upload logo image
                console.log('Fail to upload logo image')
                if (fcb) {
                    if (result.data && result.data.result_desc) {
                        fcb(result.data.result_desc, true)
                    } else {
                        fcb('error', true)
                    }
                }
            }
        })
            .catch(function (err) {
                // fail to upload logo image
                console.log('Fail to upload logo image : ', err)
                if (fcb) {
                    fcb(err, false)
                }
            });
    },
    searchAddr: function (keyword, pageNo, scb, fcb) {
        return axios.post(CONF.API_BASE_URL + '/svc/wv/search_addr', {keyword: keyword})
            .then((result) => {
                if (result.data && result.data.success) {
                    if (scb) {
                        scb(result.data.addr_list)
                    }
                } else {
                    if (fcb) {
                        if (result.data && result.data.result_desc) {
                            fcb(result.data.result_desc, true)
                        } else {
                            fcb('error', true)
                        }
                    }
                }
            })
            .catch(function (err) {
                console.log('Fail to search addr : ', err)
                if (fcb) {
                    fcb(err, false)
                }
            });
    },
}