import axios from 'axios'
import CONF from '@/config.js'

export default {
    appendTimeStamp(url) {
        let reqURL = CONF.API_BASE_URL + url
        if (reqURL.indexOf('&tm=') < 0 && reqURL.indexOf('?tm=') < 0) {
            if (reqURL.indexOf("?") > 0) {
                reqURL = reqURL + '&tm=' + new Date().getTime()
            } else {
                reqURL = reqURL + '?tm=' + new Date().getTime()
            }
        }

        return reqURL
    },

    GET(url, scb, fcb, va) {
        let reqURL = this.appendTimeStamp(url)

        return axios.get(reqURL)
            .then((result) => {
                console.log(result.data)
                if (result.data && result.data.success) {
                    if (scb) {
                        scb(result.data)
                    }
                } else {
                    if (fcb) {
                        if (result.data && result.data.result_desc) {
                            if (va && va.$noSessionProc(result.data.result_desc)) {
                                return
                            }
                            fcb(result.data.result_desc)
                        } else {
                            fcb('error')
                        }
                    }
                }
            })
            .catch(function (err) {
                if (fcb) {
                    fcb(err)
                }
            })
    },
    POST(url, param, scb, fcb, va) {
        let reqURL = this.appendTimeStamp(url)
        return axios.post(reqURL, param)
            .then((result) => {
                console.log(result.data)
                if (result.data && result.data.success) {
                    if (scb) {
                        scb(result.data)
                    }
                } else {
                    if (fcb) {
                        if (result.data && result.data.result_desc) {
                            if (va && va.$noSessionProc(result.data.result_desc)) {
                                return
                            }
                            fcb(result.data.result_desc)
                        } else {
                            fcb('error')
                        }
                    }
                }
            })
            .catch(function (err) {
                if (fcb) {
                    fcb(err)
                }
            })
    },
    PUT(url, param, scb, fcb, va) {
        let reqURL = this.appendTimeStamp(url)
        return axios.put(reqURL, param)
            .then((result) => {
                console.log(result.data)
                if (result.data && result.data.success) {
                    if (scb) {
                        scb(result.data)
                    }
                } else {
                    if (fcb) {
                        if (result.data && result.data.result_desc) {
                            if (va && va.$noSessionProc(result.data.result_desc)) {
                                return
                            }
                            fcb(result.data.result_desc)
                        } else {
                            fcb('error')
                        }
                    }
                }
            })
            .catch(function (err) {
                if (fcb) {
                    fcb(err)
                }
            })
    },
    DELETE(url, scb, fcb, va) {
        let reqURL = this.appendTimeStamp(url)
        return axios.delete(reqURL)
            .then((result) => {
                console.log(result.data)
                if (result.data && result.data.success) {
                    if (scb) {
                        scb(result.data)
                    }
                } else {
                    if (fcb) {
                        if (result.data && result.data.result_desc) {
                            if (va && va.$noSessionProc(result.data.result_desc)) {
                                return
                            }
                            fcb(result.data.result_desc)
                        } else {
                            fcb('error')
                        }
                    }
                }
            })
            .catch(function (err) {
                if (fcb) {
                    fcb(err)
                }
            })
    }
}