import commonApi from '@/api/_common.js'

export default {
    checkPassword(param, scb, fcb, va) {
        let reqURL = '/admin/check_password'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.valid_yn, va)
            }
        }, (err) => {
            console.log('api.admin.checkPassword : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    getInfo(param, scb, fcb, va) {
        let reqURL = '/admin/info'
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.company, data.admin_user, data.admin_user_list, data.admin_history_list, va)
            }
        }, (err) => {
            console.log('api.admin.getInfo : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    modifyInfo(param, scb, fcb, va) {
        let reqURL = '/admin/modify_info'
        commonApi.PUT(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            console.log('api.admin.modifyInfo : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    requestSecede(param, scb, fcb, va) {
        let reqURL = '/admin/secede'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            console.log('api.admin.requestSecede : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    addAdmin(param, scb, fcb, va) {
        let reqURL = '/admin/add'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            console.log('api.admin.addAdmin : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    delAdmin(param, scb, fcb, va) {
        let reqURL = '/admin/del'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            console.log('api.admin.delAdmin : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    giveAdmin(param, scb, fcb, va) {
        let reqURL = '/admin/give'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            console.log('api.admin.giveAdmin : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    getBillDetailList(param, scb, fcb, va) {
        let reqURL = '/admin/bill_detail'
        
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.bill_detail_list, data.total_billing_amount, data.prev_bill_month, data.prev_bill_month_paid_yn, va)
            }
        }, (err) => {
            console.log('api.admin.billDetailList : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
}
