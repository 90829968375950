import commonApi from '@/api/_common.js'
import axios from 'axios'
import CONF from '@/config.js'

export default {
    getCallbackList(param, scb, fcb, va) {
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0 && searchStart.length == 10) {
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0 && searchEnd.length == 10) {
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 20

        let reqURL = '/svc_hist/callback_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd + '&page_no=' + pageNo + '&page_size=' + pageSize
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.callback_list, data.total_count, data.total_page_count, data.page_no, va)
            }
        }, (err) => {
            console.log('api.svc_hist.getCallbackList : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },


    getRecvCallList(param, scb, fcb, va) {
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0 && searchStart.length == 10) {
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0 && searchEnd.length == 10) {
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 20
        let callType = 'INB_RECV'

        let reqURL = '/svc_hist/call_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd + '&page_no=' + pageNo + '&page_size=' + pageSize + '&call_type=' + callType
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.call_list, data.total_count, data.total_page_count, data.page_no)
            }
        }, (err) => {
            console.log('api.svc_hist.getRecvCallList : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        })
    },

    getSendCallList(param, scb, fcb, va) {
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0 && searchStart.length == 10) {
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0 && searchEnd.length == 10) {
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 20
        let callType = 'OTB_SEND'

        let reqURL = '/svc_hist/call_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd + '&page_no=' + pageNo + '&page_size=' + pageSize + '&call_type=' + callType
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.call_list, data.total_count, data.total_page_count, data.page_no)
            }
        }, (err) => {
            console.log('api.svc_hist.getRecvCallList : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        })
    },


    getSvcList(param, scb, fcb, va) {
        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0 && searchStart.length == 10) {
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0 && searchEnd.length == 10) {
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 20

        let reqURL = '/svc_hist/svc_list?search_start_date=' + searchStart + '&search_end_date=' + searchEnd + '&page_no=' + pageNo + '&page_size=' + pageSize
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.svc_list, data.total_count, data.total_page_count, data.page_no)
            }
        }, (err) => {
            console.log('api.svc_hist.getSvcList : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        })
    },


    getSvcCallList(param, scb, fcb, va) {
        let callSid = (param && param.call_sid) ? param.call_sid : ''

        let reqURL = '/svc_hist/svc_call_list?call_sid=' + callSid
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.call_list, va)
            }
        }, (err) => {
            console.log('api.svc_hist.getSvcCallList : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        })
    },

    downloadRecord: function (param, scb, fcb) {
        let seq = (param && param.seq) ? param.seq : 0
        let tm = (param && param.tm) ? param.tm : ''
        let mdn = (param && param.mdn) ? param.mdn : ''

        let fileName = 'record'
        if (tm) {
            if (tm.length > 12) {
                tm = tm.substring(0, 12)
            }
            fileName += '_' + tm
        }
        if (mdn) {
            fileName += '_' + mdn
        }
        fileName += '_' + seq + '.mp3'

        axios({
            url: CONF.API_BASE_URL + '/svc_hist/download_record/' + seq,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    },

    downloadExcel: function (param, scb, fcb, va) {
        let timestamp = new Date().getTime()

        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0 && searchStart.length == 10) {
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0 && searchEnd.length == 10) {
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }
        let callType = (param && param.call_type) ? param.call_type : 'INB_RECV'

        let reqURL = CONF.API_BASE_URL + '/svc_hist/call_list/excel?search_start_date=' + searchStart + '&search_end_date=' + searchEnd + '&call_type=' + callType + '&tm=' + timestamp

        axios({
            url: reqURL,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'call_' + callType + '_' + timestamp + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();

            if (scb) {
                scb(va)
            }
        }).catch((err) => {
            console.log('axios fail :', err);
            if (fcb) {
                fcb(err, va)
            }
        });
    },


    downloadSvcExcel: function (param, scb, fcb, va) {
        let timestamp = new Date().getTime()

        let searchStart = (param && param.search_start) ? param.search_start : ''
        let searchEnd = (param && param.search_end) ? param.search_end : ''
        if (searchStart.indexOf('-') > 0 && searchStart.length == 10) {
            searchStart = searchStart.substring(0, 4) + searchStart.substring(5, 7) + searchStart.substring(8, 10)
        }
        if (searchEnd.indexOf('-') > 0 && searchEnd.length == 10) {
            searchEnd = searchEnd.substring(0, 4) + searchEnd.substring(5, 7) + searchEnd.substring(8, 10)
        }

        let reqURL = CONF.API_BASE_URL + '/svc_hist/svc_list/excel?search_start_date=' + searchStart + '&search_end_date=' + searchEnd + '&tm=' + timestamp

        axios({
            url: reqURL,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'svc_call_' + timestamp + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();

            if (scb) {
                scb(va)
            }
        }).catch((err) => {
            console.log('axios fail :', err);
            if (fcb) {
                fcb(err, va)
            }
        });
    },


    downloadMemoExcel: function (param, scb, fcb, va) {
        let timestamp = new Date().getTime()

        let reqURL = CONF.API_BASE_URL + '/svc_hist/cust_memo_group_list/excel?tm=' + timestamp

        axios({
            url: reqURL,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'memo_' + timestamp + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();

            if (scb) {
                scb(va)
            }
        }).catch((err) => {
            console.log('axios fail :', err);
            if (fcb) {
                fcb(err, va)
            }
        });
    },

    getCustMemoGroupList(param, scb, fcb, va) {
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 20

        let reqURL = '/svc_hist/cust_memo_group_list?page_no=' + pageNo + '&page_size=' + pageSize
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.cust_memo_group_list, data.total_count, data.page_no, va)
            }
        }, (err) => {
            console.log('api.svc_hist.getCustMemoGroupList : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    getCustMemoList(param, scb, fcb, va) {
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 20

        let reqURL = '/svc_hist/cust_memo_list?page_no=' + pageNo + '&page_size=' + pageSize + '&cust_seqno=' + param.cust_seqno
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.cust_memo_list, data.cust_memo_group, va)
            }
        }, (err) => {
            console.log('api.svc_hist.getCustMemoList : FAIL : ', err)
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },

    createCustMemo(param, scb, fcb, va) {
        let url = '/svc_hist/cust_memo_create'

        commonApi.POST(url, param, data => {
            if (scb) {
                scb(data.cust_seqno, va)
            }
        }, err => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    }
}