import commonApi from '@/api/_common.js'

export default {
    getCategoryList(searchParam, scb, fcb, va) {
        let reqURL = '/company_category/list'

        return commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.category_list, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    }
}