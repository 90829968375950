import Vue from 'vue'
import svcVoiceIntroApi from '@/api/svc_voice_intro'

export default {
    namespaced: true,
    state: {
        introMent: '',
        workOffMent: '',
        workTimeConf: {
            workHoursUseYn: 'N',
            workHours: [],
            holidayUseYn: 'N'
        },
        breakTimeConf: {
            breakTimeUseYn: 'N',
            breakTimes: [],
            defaultBreakStart: '1200',
            defaultBreakEnd: '1300',
        },
        validFlag: false,
        validMsg: '',
        floatingOptions: {}
    },
    getters: {
        getWorkTimeConf: function (state) {
            return state.workTimeConf
        }
    },
    actions: {
        getConfig({commit}, payload) {
            svcVoiceIntroApi.getConfig({}, (c, va) => {
                commit('setIntroMent', c.intro_ment)
                commit('setWorkOffMent', c.work_off_ment)
                commit('setWorkTimeConf', c)
                commit('setBreakTimeConf', c)
                if (payload.scb) {
                    payload.scb(va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err)
                }
            }, payload.va)
        },
        doSubmit({state, commit}, payload) {
            svcVoiceIntroApi.doSubmit({
                intro_ment: state.introMent,
                work_off_ment: state.workOffMent,
                work_hours_use_yn: state.workTimeConf.workHoursUseYn,
                work_hours: state.workTimeConf.workHours,
                holiday_use_yn: state.workTimeConf.holidayUseYn,
                break_time_use_yn: state.breakTimeConf.breakTimeUseYn,
                break_times: state.breakTimeConf.breakTimes
            }, () => {
                if (payload.scb) {
                    payload.scb()
                }
            }, (err) => {
                if (payload.fcb) {
                    payload.fcb(err)
                }
            })
        }
    },
    mutations: {
        setIntroMent(state, c) {
            state.introMent = c
        },
        setWorkOffMent(state, c) {
            state.workOffMent = c
        },
        setWorkTimeConf(state, c) {
            state.workTimeConf.workHoursUseYn = (c.work_hours_use_yn) ? c.work_hours_use_yn : 'N'
            state.workTimeConf.workHours = (c.work_hours) ? c.work_hours : []
            state.workTimeConf.holidayUseYn = (c.holiday_use_yn) ? c.holiday_use_yn : 'N'
        },
        setBreakTimeConf(state, c) {
            state.breakTimeConf.breakTimeUseYn = (c.break_time_use_yn) ? c.break_time_use_yn : 'N'
            state.breakTimeConf.breakTimes.splice(0, state.breakTimeConf.breakTimes.length)

            if (c.break_times && c.break_times.length != 0) {
                c.break_times.forEach((item, idx) => {
                    state.breakTimeConf.breakTimes.push({
                        'idx': idx,
                        'break_start': item.break_start,
                        'break_end': item.break_end,
                        'break_ment': item.break_ment,
                        'break_start_hour': item.break_start.substring(0, 2),
                        'break_start_min': item.break_start.substring(2, 4),
                        'break_end_hour': item.break_end.substring(0, 2),
                        'break_end_min': item.break_end.substring(2, 4),
                        'change_start_hour_flag': false,
                        'change_start_min_flag': false,
                        'change_end_hour_flag': false,
                        'change_end_min_flag': false,
                        'error': {
                            'flag': false,
                            'msg': '',
                            'type': ''
                        }

                    })
                })
            }
        },
        addWorkHours(state, c) {
            if (!state.workTimeConf.workHours) {
                state.workTimeConf.workHours = []
            }
            state.workTimeConf.workHours.push(c)
        },
        modifyWorkHours(state, c) {
            if (!state.workTimeConf.workHours) {
                state.workTimeConf.workHours = []
            }
            if (state.workTimeConf.workHours.length > c.idx) {
                Vue.set(state.workTimeConf.workHours, c.idx, c.item)
            }
        },
        delWorkHours(state, c) {
            if (!state.workTimeConf.workHours) {
                state.workTimeConf.workHours = []
            }
            if (state.workTimeConf.workHours.length > c) {
                state.workTimeConf.workHours.splice(c, 1)
            }
        },
        setHolidayUseYn(state, c) {
            state.workTimeConf.holidayUseYn = c
        },
        setBreakTimeUseYn(state, c) {
            state.breakTimeConf.breakTimeUseYn = c
        },
        removeBreakTime(state, c) {
            state.breakTimeConf.breakTimes = state.breakTimeConf.breakTimes.splice(c, 1)
        },
        addBreakTime(state, {}) {
            state.breakTimeConf.breakTimes.push({
                'idx': state.breakTimeConf.breakTimes.length,
                'break_start': state.breakTimeConf.defaultBreakStart,
                'break_end': state.breakTimeConf.defaultBreakEnd,
                'break_ment': '',
                'break_start_hour': state.breakTimeConf.defaultBreakStart.substring(0, 2),
                'break_start_min': state.breakTimeConf.defaultBreakStart.substring(2, 4),
                'break_end_hour': state.breakTimeConf.defaultBreakEnd.substring(0, 2),
                'break_end_min': state.breakTimeConf.defaultBreakEnd.substring(2, 4),
                'change_start_hour_flag': false,
                'change_start_min_flag': false,
                'change_end_hour_flag': false,
                'change_end_min_flag': false,
                'error': {
                    'flag': false,
                    'msg': '',
                    'type': ''
                }
            })
        },
        setFloatingOptions(state, c) {
            state.floatingOptions = Object.assign({}, c)
        }
    }
}