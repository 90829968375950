import companyCategoryApi from '@/api/company_category'

export default {
    namespaced: true,
    state: {
        categoryList: []
    },
    actions: {
        getCategoryList({commit}, payload) {
            return companyCategoryApi.getCategoryList({}, (categoryList, va) => {
                commit('setCategoryList', categoryList)

                if (payload.scb) {
                    payload.scb(categoryList, va)
                }
            }, (err, va) => {
                commit('setCategoryList', null)

                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
    },
    mutations: {
        setCategoryList(state, c) {
            state.categoryList.splice(0, state.categoryList.length)
            c.forEach(function (item, idx) {
                state.categoryList.push(item)
            })
            state.totalCount = (c) ? c.length : 0
        }
    }
}