import commonApi from '@/api/_common.js'

export default {
    getMenuList  (param, scb, fcb, va) { 
        let reqURL = '/svc/voice_scn/menu_list'
        commonApi.GET(reqURL, (data) => { 
            if(scb) {
                scb(data.menu_list)
            }
        }, (err) => { 
            console.log('api.svc_voice_scn.getMenuList : FAIL : ', err)
            if(fcb) { 
                fcb(err)
            }
        }, va) 
    },
    addMenu (param, scb, fcb, va) { 
        let reqURL = '/svc/voice_scn/add_menu'
        commonApi.POST(reqURL, param, (data) => { 
            if(scb) {
                scb(va)
            }
        }, (err) => { 
            console.log('api.svc_voice_scn.addMenu : FAIL : ', err)
            if(fcb) { 
                fcb(err, va)
            }
        }, va) 
    },
    modifyMenu (param, scb, fcb, va) { 
        let reqURL = '/svc/voice_scn/modify_menu'
        commonApi.PUT(reqURL, param, (data) => { 
            if(scb) {
                scb()
            }
        }, (err) => { 
            console.log('api.svc_voice_scn.modifyMenu : FAIL : ', err)
            if(fcb) { 
                fcb(err)
            }
        }, va)
    },
    changeMenuOrderList (param, scb, fcb, va) { 
        let reqURL = '/svc/voice_scn/change_order_list'
        commonApi.PUT(reqURL, param, (data) => { 
            if(scb) {
                scb()
            }
        }, (err) => { 
            console.log('api.svc_voice_scn.changeMenuOrderList : FAIL : ', err)
            if(fcb) { 
                fcb(err)
            }
        }, va)        
    },
    deleteMenu (param, scb, fcb, va) { 
        let reqURL = '/svc/voice_scn/delete_menu'
        commonApi.PUT(reqURL, param, (data) => { 
            if(scb) {
                scb()
            }
        }, (err) => { 
            console.log('api.svc_voice_scn.deleteMenu : FAIL : ', err)
            if(fcb) { 
                fcb(err)
            }
        }, va)        
    }
}