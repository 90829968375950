import payApi from '@/api/pay'

export default {
    namespaced: true,
    state: {
        companyPay: {},
        billList: [],
        billDetailList: [],
        curBill: null,
    },
    getters: {
        getCompanyPay(state) {
            return state.companyPay
        },
        getBillList(state) {
            return state.billList
        },
        getBillDetailList(state) {
            return state.billDetailList
        },
        getCurBill(state) {
            return state.curBill
        }
    },
    actions: {
        getCompanyPay({state, commit}, payload) {
            payApi.getCompanyPay({}, (companyPay, billList, va) => {
                commit('setCompanyPay', companyPay)
                commit('setBillList', billList)

                if (payload.scb) {
                    payload.scb(companyPay, billList, va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        },
        getBillDetailList({state, commit}, payload) {
            payApi.getBillDetailList({
                bill_seqno: payload.bill_seqno
            }, (billDetailList, va) => {
                commit('setBillDetailList', billDetailList)
                if (payload.scb) {
                    payload.scb(billDetailList, va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        }
    },
    mutations: {
        setCompanyPay(state, c) {
            if (!c) {
                return
            }

            state.companyPay = Object.assign({}, c)
        },
        setBillList(state, c) {
            state.billList.splice(0, state.billList.length)
            if (c) {
                c.forEach(item => {
                    state.billList.push(item)
                })
            }
        },
        setBillDetailList(state, c) {
            state.billDetailList.splice(0, state.billDetailList.length)
            if (c) {
                c.forEach(item => {
                    state.billDetailList.push(item)
                })
            }
        },
        setCurBill(state, c) {
            state.curBill = Object.assign([], c)
        }
    }
}