import Vue from 'vue'
import Vuex, {createNamespacedHelpers} from 'vuex'
import login from '@/store/modules/login'
import join from '@/store/modules/join'
import dashboard from '@/store/modules/dashboard'
import svcVoiceScn from '@/store/modules/svc_voice_scn'
import svcVoiceIntro from '@/store/modules/svc_voice_intro'
import svcStaff from '@/store/modules/svc_staff'
import svcWv from '@/store/modules/svc_wv'
import svc_hist_callback from '@/store/modules/svc_hist_callback'
import svc_hist_recv from '@/store/modules/svc_hist_recv'
import svc_hist_send from '@/store/modules/svc_hist_send'
import svc_hist_svc from '@/store/modules/svc_hist_svc'
import svc_hist_cust_memo from '@/store/modules/svc_hist_cust_memo'
import stats_menu from '@/store/modules/stats_menu'
import stats_user from '@/store/modules/stats_user'
import stats_svc_call from '@/store/modules/stats_svc_call'
import admin from '@/store/modules/admin'
import pay from '@/store/modules/pay'
import svc_block_mdn from '@/store/modules/svc_block_mdn'
import company_category from '@/store/modules/company_category'
import addr_book from '@/store/modules/addr_book'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        dashboard,
        svcVoiceScn,
        login,
        join,
        svcStaff,
        svcVoiceIntro,
        svcWv,
        admin,
        pay,
        svc_hist_callback,
        svc_hist_recv,
        svc_hist_send,
        svc_hist_svc,
        svc_hist_cust_memo,
        stats_menu,
        stats_user,
        stats_svc_call,
        svc_block_mdn,
        company_category,
        addr_book
    },
    state: {
        layoutFlag: false,
        menuId: '',
        popupModal: false,
        popupData: {
            type: '',
            title: '',
            message: '',
            sub_message: '',
            okCb: null,
            closeCb: null,
            va: null,
            accept_text: '',
            cancel_text: '',
            addrBook: {
                addr_book_seqno: 0,
                cust_name: '',
                cust_mdn: '',
                cust_desc: '',
                pageMode: ''
            }
        },
        showAgree: {
            type: '',
            isShow: false
        },
        footerLoaded: ''
    },
    mutations: {
        layoutFlag(state, payload) {
            state.layoutFlag = payload
        },
        menuId(state, payload) {
            state.menuId = payload
        },
        popupData(state, payload) {
            state.popupData.type = payload.type
            state.popupData.title = payload.title
            state.popupData.message = payload.message
            state.popupData.sub_message = payload.sub_message
            state.popupData.okCb = payload.okCb
            state.popupData.closeCb = payload.closeCb
            state.popupData.va = payload.va
            state.popupData.accept_text = payload.accept_text
            state.popupData.cancel_text = payload.cancel_text
            state.popupData.addrBook = Object.assign({}, payload.addrBook)
        },
        popupModal(state, val) {
            state.popupModal = val
            if (!val) {
                state.popupData.type = 'none'
            }
        },
        setAgree(state, c) {
            state.showAgree = Object.assign({}, c)
        },
        setFooterLoaded(state, c) {
            state.footerLoaded = c
        }
    },
    getters: {
        layoutFlag(state) {
            return state.layoutFlag
        },
        menuId(state) {
            return state.menuId
        },
        popupModal(state) {
            return state.popupModal
        },
        popupData(state) {
            return state.popupData
        },
        popupType(state) {
            if (state.popupData && state.popupData.type) {
                return state.popupData.type
            }
            return 'none'
        },
        showAgree(state) {
            return state.showAgree
        },
        footerLoaded(state) {
            return state.footerLoaded
        }
    },
    actions: {
        openAlert({commit}, {title, message, sub_message, closeCb, va}) {
            if (!title) {
                title = '알림'
            }
            commit('popupData', {type: 'alert', 'title': title, 'message': message, 'sub_message': sub_message, closeCb: closeCb, va: va})
            commit('popupModal', true)
        },
        openConfirm({commit}, {title, message, sub_message, okCb, closeCb, acceptText, cancelText, va}) {
            if (!title) {
                title = '확인'
            }

            if (!acceptText) {
                acceptText = '확인'
            }

            if (!cancelText) {
                cancelText = '취소'
            }

            commit('popupData', {
                type: 'confirm',
                'title': title,
                'message': message,
                'sub_message': sub_message,
                okCb: okCb,
                closeCb: closeCb,
                va: va,
                accept_text: acceptText,
                cancel_text: cancelText
            })
            commit('popupModal', true)
        },
        closePopup({commit, state}) {
            let va = (state && state.popupData) ? state.popupData.va : null
            commit('popupModal', false)

            if (state && state.popupData && state.popupData.closeCb) {
                try {
                    if (state.popupData.va) {
                        state.popupData.va.$nextTick(() => {
                            state.popupData.closeCb(state.popupData.va)
                        })
                    } else {
                        state.popupData.closeCb()
                    }
                } catch (e) {
                    console.log('fail to execute popup callback : ', e)
                }
            }
        },
        okPopup({commit, state}) {
            commit('popupModal', false)
            if (state && state.popupData && state.popupData.okCb) {
                try {
                    if (state.popupData.va) {
                        state.popupData.va.$nextTick(() => {
                            state.popupData.okCb(state.popupData.va)
                        })
                    } else {
                        state.popupData.okCb()
                    }
                } catch (e) {
                    console.log('fail to execute popup callback : ', e)
                }
            }
        },
        openAddrBook({commit}, {addrBook, okCb, closeCb, va}) {
            commit('popupData', {
                type: 'addrBook',
                addrBook: addrBook,
                okCb: okCb,
                closeCb: closeCb,
                va: va
            })
            commit('popupModal', true)
        },
    },
})
