import commonApi from '@/api/_common.js'

export default {
    getList(param, scb, fcb, va) {
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size: 25
        let reqURL = '/svc/block_mdn_list?page_no=' + pageNo + '&page_size=' + pageSize

        if (param.search_type) {
            reqURL += '&search_type=' + param.search_type
        }

        if (param.search_text) {
            reqURL += '&search_text=' + param.search_text
        }

        if (param.block_yn) {
            reqURL += '&block_yn=' + param.block_yn
        }

        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.block_mdn_list, data.total_count, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    }
}
