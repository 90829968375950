import commonApi from '@/api/_common.js'
import CONF from '@/config.js'
import axios from "axios";

export default {
    getList(param, scb, fcb, va) {
        let pageNo = (param && param.page_no) ? param.page_no : 1
        let pageSize = (param && param.page_size) ? param.page_size : 25
        let reqURL = '/addr_book/list?page_no=' + pageNo + '&page_size=' + pageSize

        if (param.search_type) {
            reqURL += '&search_type=' + param.search_type
        }

        if (param.search_text) {
            reqURL += '&search_text=' + param.search_text
        }

        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.addr_book_list, data.total_count, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    getOne(param, scb, fcb, va) {
        let reqURL = '/addr_book/detail/' + param.addr_book_seqno

        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.addr_book, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    createAddrBook(param, scb, fcb, va) {
        let reqURL = '/addr_book/create'

        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    updateAddrBook(param, scb, fcb, va) {
        let reqURL = '/addr_book/update/' + param.addr_book_seqno

        commonApi.PUT(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    deleteAddrBook(param, scb, fcb, va) {
        let reqURL = '/addr_book/delete'
        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    downloadExcel(param, scb, fcb, va) {

        let timestamp = new Date().getTime()
        let reqURL = CONF.API_BASE_URL + '/addr_book/list_excel'

        if (param.search_type) {
            reqURL += '?search_type=' + param.search_type
        }

        if (param.search_text) {
            reqURL += '&search_text=' + param.search_text
        }

        axios({
            url: reqURL,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'address_book_' + timestamp + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();

            if (scb) {
                scb(va)
            }
        }).catch((err) => {
            console.log('axios fail :', err);

            if (fcb) {
                fcb(err)
            }
        });
    },
    uploadExcelTmp: function (param, scb, fcb) {
        let formData = new FormData()
        formData.append('content_file', param.file)

        return axios.post(CONF.API_BASE_URL + '/addr_book/upload_excel',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: param.progressEvent
            }
        ).then(result => {
            if (result.data && result.data.success) {
                if (scb) {
                    scb(result.data)
                }
            } else {
                if (fcb) {
                    if (result.data && result.data.result_desc) {
                        fcb(result.data.result_desc, true)
                    } else {
                        fcb('error', true)
                    }
                }
            }
        })
        .catch(function (err) {
            console.log('Fail to upload excel : ', err)
            if (fcb) {
                fcb(err, false)
            }
        })
    },
    createAddrBookBulk(param, scb, fcb, va) {
        let reqURL = '/addr_book/create_bulk'

        commonApi.POST(reqURL, param, (data) => {
            if (scb) {
                scb(data.succ_count, data.fail_count, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    },
    checkExitsCustMdn(param, scb, fcb, va) {
        let reqURL = '/addr_book/check_exists_cust_mdn/' + param.cust_mdn

        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.exists_yn, va)
            }
        }, (err) => {
            if (fcb) {
                fcb(err, va)
            }
        }, va)
    }
}