import commonApi from '@/api/_common.js'

export default {

    getInfo(param, scb, fcb, va) {
        let reqURL = '/dashboard/info'
        commonApi.GET(reqURL, (data) => {
            if (scb) {
                scb(data.company, data.stat, data.staff_list, va)
            }
        }, (err) => {
            console.log('api.dashboard.getInfo : FAIL : ', err)
            if (fcb) {
                fcb(err)
            }
        }, va)
    },

}
