import svcBlockMdnApi from '@/api/svc_block_mdn'

export default {
    namespaced: true,
    state: {
        searchParam: {},
        blockMdnList: [],
        totalCount: 0
    },
    actions: {
        getList({commit}, payload) {

            commit('setSearchParam', payload.param)

            svcBlockMdnApi.getList(payload.param, (blockMdnList, totalCount, va) => {
                commit('setBlockMdnList', blockMdnList)
                commit('setTotalCount', totalCount)

                if (payload.scb) {
                    payload.scb()
                }

            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        }
    },
    mutations: {
        setBlockMdnList(state, c) {
            state.blockMdnList.splice(0, state.blockMdnList.length)

            if (c) {
                c.forEach(item => {
                    state.blockMdnList.push(item)
                })
            }
        },
        setTotalCount(state, c) {
            state.totalCount = c
        },
        setSearchParam(state, c) {
            state.searchParam = Object.assign({}, c)
        }
    }
}