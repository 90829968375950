import Vue from 'vue'
import svcWvApi from '@/api/svc_wv'

export default {
    namespaced: true,
    state: {
        wvUseYn: "N",
        companyName: {
            name_text: '',
            logo_use_yn: 'N',
            logo_url: ''
        },
        companyIntroList: [
            {image_url_list: [], intro_text: ''}
        ],
        companyAddr: {
            post_no: '',
            addr1: '',
            addr2: '',
            google_map_lat: '',
            google_map_lng: '',
            addr_desc: ''
        }
    },
    getters: {},
    actions: {
        getConfig({commit}, payload) {
            svcWvApi.getConfig({}, c => {
                commit('setConfig', c)
                if (payload.scb) {
                    payload.scb(c)
                }
            }, err => {
                if (payload.fcb) {
                    payload.fcb(err)
                }
            }, payload.va)
        },
        doSubmit({state, commit}, payload) {
            svcWvApi.doSubmit({
                wv_use_yn: state.wvUseYn,
                company_name: state.companyName,
                company_intro_list: state.companyIntroList,
                company_addr: state.companyAddr
            }, () => {
                if (payload.scb) {
                    payload.scb()
                }
            }, (err) => {
                if (payload.fcb) {
                    payload.fcb(err)
                }
            }, payload.va)
        }
    },
    mutations: {
        setConfig(state, c) {
            if (!c) {
                return
            }

            if (c.wv_use_yn) {
                state.wvUseYn = c.wv_use_yn
            }
            if (c.company_name) {
                let conf = c.company_name
                state.companyName = Object.assign({}, conf)
            }
            if (c.company_intro_list) {
                let confs = c.company_intro_list
                state.companyIntroList.splice(0, state.companyIntroList.length)
                confs.forEach(item => {
                    state.companyIntroList.push(item)
                })
            }
            if (c.company_addr) {
                let conf = c.company_addr
                state.companyAddr = Object.assign({}, conf)
            }
        },
        setWvUseYn(state, c) {
            state.wvUseYn = c
        }
    }
}