
import joinApi from '@/api/join'

export default {
    namespaced: true,
    state: {
        joinStep: 1,
        joinFormStep01: {},
        joinFormStep02: {},
        joinFormStep03: {},
        joinFormStep04: {},
        product: {}
    },
    getters: {
        getJoinStep (state) {
            return state.joinStep
        },
        getJoinFormStep01(state) {
            return state.joinFormStep01
        },
        getJoinFormStep02(state) {
            return state.joinFormStep02
        },
        getJoinFormStep03(state) {
            return state.joinFormStep03
        },
        getJoinFormStep04(state) {
            return state.joinFormStep04
        },
        getJoinTid(state) {
            let joinTid = state.joinFormStep01.join_tid
            return joinTid ? joinTid : ''
        },
        getProduct(state) {
            return state.product
        }
    },
    actions: {
        init({commit}) {
            commit('setJoinStep', 1)
            commit('setJoinFormStep01', {})
            commit('setJoinFormStep02', {})
            commit('setJoinFormStep03', {})
            commit('setJoinFormStep04', {})
            commit('setProduct', {})
        },
        getJoinStepInfo({commit}, payload) {
            return joinApi.getJoinStepInfo(payload.param, (joinFormStep01, joinFormStep02, joinFormStep03, joinFormStep04, product, va) => {

                commit('setJoinTid', payload.param.join_tid)
                commit('setJoinFormStep01', joinFormStep01 ? joinFormStep01 : {})
                commit('setJoinFormStep02', joinFormStep02 ? joinFormStep02 : {})
                commit('setJoinFormStep03', joinFormStep03 ? joinFormStep03 : {})
                commit('setJoinFormStep04', joinFormStep04 ? joinFormStep04 : {})
                commit('setProduct', product ? product : {})

                if (payload.scb) {
                    payload.scb(va)
                }
            }, (err, va) => {
                if (payload.fcb) {
                    payload.fcb(err, va)
                }
            }, payload.va)
        }
    },
    mutations: {
        setJoinStep(state, c) {
            state.joinStep = c
        },
        setJoinFormStep01(state, c) {
            if (c) {
                state.joinFormStep01 = Object.assign({}, c)
            }
        },
        setJoinFormStep02(state, c) {
            if (c) {
                state.joinFormStep02 = Object.assign({}, c)
            }
        },
        setJoinFormStep03(state, c) {
            if (c) {
                state.joinFormStep03 = Object.assign({}, c)
            }
        },
        setJoinTid(state, c) {
            state.joinFormStep01.join_tid = c
        },
        setProduct(state, c) {
            state.product = Object.assign({}, c)
        },
        setJoinFormStep04(state, c) {
            state.joinFormStep04 = Object.assign({}, c)
        }
    }
}